@import "Custom";
@import "LoaderOverlay";

html, body {
    min-height: 100vh;
}

html, body {
    padding: 0;
    margin: 0;
}

html {
    height: 100vh;
}

body {
    min-height: 100vh;
}

.root {
    min-height: 100vh;
    height: 100vh;
    padding: 0;
    margin: 0;
}