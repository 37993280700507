﻿._loading_overlay_wrapper {
    /*    display: flex;
    flex-direction: column;
    flex-grow: 1;*/
    height: 100vh;
    z-index: 3000;
    display: block;
    position: absolute;
}

._loading_overlay_overlay {
    background: rgba(0,0,0,0.4);
}

._loading_overlay_content {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10rem;
}

._loading_overlay_spinner svg circle {
    stroke: #660000;
    stroke-width: 4 !important;
}
